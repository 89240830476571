



























import {Component, Vue} from 'vue-property-decorator';
import Customer from '@/models/Customer';
import Location from '@/models/Location';
import {namespace} from 'vuex-class';
import {locationStoreGetter} from '@/stores/location.store';
import {customerStoreGetter} from '@/stores/customer.store';
import Topic from '@/models/Topic';
import { getTrafficAreas } from '@/misc/Enums/Constants';

interface MasterData {
  title: string;
  item: any;
  shownData: string[];
  default?: string;
}

const CustomerStore = namespace('customer');
const LocationStore = namespace('location');

const LOCATION_ATTRIBUTE = 'location';
const CUSTOMER_ATTRIBUTE = 'customer';

@Component({})
export default class LocationMasterDataComponent extends Vue {
  @LocationStore.Getter(locationStoreGetter.LOCATION)
  public _location!: Location;

  private get [LOCATION_ATTRIBUTE]() {

    return this._location;
  }


  private get masterData(): MasterData[] {
    return [
      {
        title: this.$t('GENERAL.GENERAL').toString(),
        item: this[LOCATION_ATTRIBUTE],
        shownData: ['name', 'projectId', 'diversions.length', 'description'],
      },
      {
        title: this.$t('GENERAL.ADDRESS').toString(),
        item: this[LOCATION_ATTRIBUTE],
        shownData: [
          'address.street',
          'address.house_No',
          'address.postal_Code',
          'address.city',
        ],
        default: 'address',
      },
      {
        title: this.$t('LOCATIONS_DASHBOARD.SPECIFIC').toString(),
        item: this[LOCATION_ATTRIBUTE],
        shownData: ['trafficArea', 'manager.fullName', 'topics', 'emergencyPhone'],
      },
      {
        title: this.$t('GENERAL.CUSTOMER').toString(),
        item: this[LOCATION_ATTRIBUTE],
        shownData: ['customer.name', 'customer.orderId', 'customer.accountantName', 'customer.phone'],
      },
      {
        title: this.$t('LOCATION_MANAGE.TERM.TERM').toString(),
        item: this[LOCATION_ATTRIBUTE],
        shownData: ['startDate', 'endDate'],
      },
    ];
  }

  private returnData(rowData: MasterData): Array<{value: any, name: string}> {
    // the cols
    return rowData.shownData.map((colData: string) => {
      let retVal: any = rowData.item;
      let name: string = '';
      colData.split('.').forEach((attribute: string, index: number) => {
        name = attribute.toUpperCase();
        const attr = attribute.replace('_', '');
        // index === 0
        if (!retVal[attr]) {
          if (!index && rowData.default) {
            // if there is no in that attribute, fallback to default once
            retVal = retVal[rowData.default];
          } else {
            retVal = '-';
          }
        } else {
          retVal = retVal[attr];
        }
      });
      if (retVal !== undefined && retVal !== null) {
        if (Array.isArray(retVal)) {
          return {
            value: retVal.map((topic) => this.$t(`LOCATIONS_DASHBOARD.ATTRIBUTES.${topic}`).toString() ).join(', '),
            name,
          };
        }
        switch (typeof retVal) {
          case 'number':
          case 'bigint':
            return {
              value: retVal,
              name,
            };
          case 'boolean':
            return retVal ? this.$t('GENERAL.YES') : this.$t('GENERAL.NO');
          case 'string':
            if (getTrafficAreas().toString().includes(retVal)) {
              return {
                value: this.$t(`LOCATIONS_DASHBOARD.ATTRIBUTES.${retVal}`).toString(),
                name,
              };
            }
            return retVal ? {
              name,
              value: retVal,
            } : {
              value: '-',
              name,
            };
          default:
            console.log('something I did not recognize: ', retVal);
            return retVal ? retVal : '-';
        }
      }
      return {
        value: retVal,
        name,
      };
    });
  }
}
